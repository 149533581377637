/* .main-container {

} */

.text-span {
    font-size: 20px;
}

.header-span-div {
    text-align: center;
    margin-bottom: 12px;
}

.header-span {
    font-size: 19px;
}

.header-span-bold {
    font-weight: 600;
}

.simple-elements-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 6px;
}
.simple-element-img {
    height: 72px;
    width: 72px;
}

.simple-element-info {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 6px;
}

.info-texts-container {
    display: flex;
    flex-direction: column;
}

.x-text-span {
    font-size: 17px;
}

.wild-container {
    display: flex;
    flex-direction: row;
}

.wild-img-container {
    display: flex;
    flex-direction: column;
}

.wild-element-img {
    height: 100px;
    width: 100px;
}

.wild-description {
    font-size: 19px;
    text-align: left;
}

.br-margin {
    margin-bottom: 12px;
}

.wild-description-container {
    position: relative;
}

.wild-description-img {
    position: relative;
    top: 5px;
    width: 19px;
    height: 19px;
}

.bonus-container {
    display: flex;
    flex-direction: row;
}

.bonus-element-img {
    height: 100px;
    width: 100px;
}

.free-spins-rules {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rules-header {
    font-size: 22px;
    font-weight: 700;
}

.rules-body {
    font-size: 17px;
}

.rules-header-div {
    text-align: center;
    margin-bottom: 12px;
}

.rules-body-div {
    text-align: left;
}

.volatile-container {
    display: flex;
    flex-direction: column;
}

.lines-img {
    width: 100%;
    margin-bottom: 12px;
}
