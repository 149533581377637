.bet-increase-group {
    display: flex;
    justify-content: center;
    width: 100%;
}

/* .button-left {
    padding-left: 16px;
}

.button-right {
    padding-right: 16px;
} */

.bet-input {
    margin-left: 5%;
    margin-right: 5%;
}

.balance-container {
    display: flex; /* Устанавливаем флекс-контейнер для горизонтального выравнивания */
    align-items: center;
    justify-content: center;
    width: 100%
}
  
.no-padding-bottom-formitem {
    padding-bottom: 0px;
}

/* .input {
    text-align: center;
} */

.spin-info {
    
    text-align: center;
    font-size: medium;
    margin-top: 2%;
    margin-bottom: 2%;
   
}

.do-spin-button {
    width: 100%;
    height: 60px;
    
    /* margin-left: 16px;
    margin-right: 16px;
    margin-top: 12px; */

}

.stop-auto {
    color: red;
}

.do-spin-button-text {
    font-size: 20px;
    font-weight: 500;
}

.balance-spinner {
    margin-left: 1%;
    height: auto; 
    width: auto; 
}



.spin-info-down {
    font-weight: 600;
    font-size: 22px;
}

.removed-margin-bottom {
    margin-bottom: 0;
}

.input-animate {
    animation: 0.5s ease-in-out 0s normal none running trambling-animation;
}

@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: translate(0px);
    }

    10%, 30% {
        transform: translate(-10px);
    }

    20%, 40% {
        transform: translate(10px);
    }
}
.spin-info-up {
   margin-left: 3px;
}

.opacity-changer-down {
    opacity: 1;
    animation: blink-down 2s infinite;
  }
  
  @keyframes blink-down {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .opacity-changer-up {
    opacity: 0;
    animation: blink-up 2s infinite;
  }
  
  @keyframes blink-up {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .opacity-changer {
    opacity: 0;
    animation: blink 3s infinite;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .opacity-changer-bonus-got {
    opacity: 0;
    animation: blink-bonus 5400ms infinite;
  }
  
  @keyframes blink-bonus {
    0%, 100% {
      opacity: 0;
    }
    28% {
      opacity: 1;
    }
    56% {
      opacity: 0;
    }
    84% {
      opacity: 1;
    }
  }
