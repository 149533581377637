.slot-machine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reel {
  height: 222px;
  width: 20vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
  
}

.element {
  width: 72px;
  height: 72px;
  margin-bottom: 2px;
}

.raise-0 {
  transform: translateY(-3888px);
}
.raise-1 {
  transform: translateY(-6048px);
}
.raise-2 {
  transform: translateY(-8208px);
}
.raise-3 {
  transform: translateY(-10368px);
}
.raise-4 {
  transform: translateY(-12528px);
}
.raise-turbo {
  transform: translateY(-3600px);
}

.down-0 {
  transform: translateY(0%);
  transition: transform 1s cubic-bezier(0, 0.9, 0.8, 1.01);
}

.down-1 {
  transform: translateY(0%);
  transition: transform 1.5s cubic-bezier(0, 0.9, 0.8, 1.01);
}

.down-2 {
  transform: translateY(0%);
  transition: transform 2s cubic-bezier(0, 0.9, 0.8, 1.01);
}

.down-3 {
  transform: translateY(0%);
  transition: transform 2.5s cubic-bezier(0, 0.9, 0.8, 1.01);
}

.down-4 {
  transform: translateY(0%);
  transition: transform 3s cubic-bezier(0, 0.9, 0.8, 1.01);
}

.down-turbo {
  transform: translateY(0%);
  transition: transform 0.5s cubic-bezier(0, 0.9, 0.8, 1.01);
}

.x2 {
  border-radius: 10px;
  background: linear-gradient(145deg, rgba(121,121,121,1) 0%, rgba(215,215,215,1) 100%);
  
}

.x3 {
  border-radius: 10px;
  background: linear-gradient(145deg, rgba(162,80,25,1) 0%, rgba(235,172,96,1) 100%);
}